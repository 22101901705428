body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1 em !important;
  background-color: #F3F4F4;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.in-text::placeholder {  
    opacity: 0.0;  
    color:#f4f6f8 
}
@media only screen and (max-width: 1200px) {
  .in-text::placeholder {
    font-size: 11px;
    opacity: 0.0;   
}

}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.479);
}
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
.hvr button:hover { background-color: black;}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bx {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.pagination {
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  font-size: 12px;
}

ul.pagination li a {
  text-decoration: none;
  color: black;
  font-size: 12px;
}

ul.pagination li.active a {
  background-color: grey;
  border: none;
  color: white;
}
ul.pagination li.active {
  background-color: grey;
}

ul.pagination li a:hover {
  color: black;
}

ul.pagination li a.active,
a:hover {
  color: white;
}

.page-selection {
  width: 30px;
  height: 30px;
  color: black;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-359deg);
    }
  }

.rotate {
   /* animation: rotation 2s infinite linear;    
   */
   animation: rotation 2s linear; 
} 

.rotate-inf {
   animation: rotation 2s infinite linear;   
 } 

 .bb:hover {
  background-color: black;
 }

